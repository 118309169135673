import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i2 from '@priva/components/expander';
import { PrivaExpanderModule } from '@priva/components/expander';
import * as i3 from '@priva/components/header';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaLocalizationModule } from '@priva/localization';
import * as i4 from '@ngx-translate/core';
const _c0 = ["*", [["", "data-footer", ""]], [["", "data-image", ""]], [["", "data-expander", ""]]];
const _c1 = ["*", "[data-footer]", "[data-image]", "[data-expander]"];
const _c2 = a0 => [a0];
function PrivaStatusGlobalComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
}
function PrivaStatusGlobalComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.iconClass);
  }
}
function PrivaStatusGlobalComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "priva-expander", 10);
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵlistener("triggerClick", function PrivaStatusGlobalComponent_Conditional_10_Template_priva_expander_triggerClick_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.isExpanded = !ctx_r0.isExpanded);
    });
    i0.ɵɵprojection(2, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("isExpanded", ctx_r0.isExpanded)("triggerText", i0.ɵɵpipeBind1(1, 2, ctx_r0.isExpanded ? "GLOBAL.ACTION.EXPANDER.LABEL.OPEN" : "GLOBAL.ACTION.EXPANDER.LABEL.CLOSE"));
  }
}
var PrivaStatusGlobalStatus;
(function (PrivaStatusGlobalStatus) {
  PrivaStatusGlobalStatus["default"] = "";
  PrivaStatusGlobalStatus["info"] = "status-message-info";
  PrivaStatusGlobalStatus["success"] = "status-message-success";
  PrivaStatusGlobalStatus["warning"] = "status-message-warning";
  PrivaStatusGlobalStatus["danger"] = "status-message-danger";
})(PrivaStatusGlobalStatus || (PrivaStatusGlobalStatus = {}));
class PrivaStatusGlobalComponent {
  constructor() {
    this.status = 'default';
    this.hasImage = false;
    this.hasExpander = false;
    this.theme = 'onlightgray';
    this.isExpanded = false;
    this.PrivaStatusGlobalStatus = PrivaStatusGlobalStatus;
  }
  static {
    this.ɵfac = function PrivaStatusGlobalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaStatusGlobalComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaStatusGlobalComponent,
      selectors: [["priva-status-global"]],
      inputs: {
        status: "status",
        iconClass: "iconClass",
        title: "title",
        subtitle: "subtitle",
        hasImage: "hasImage",
        hasExpander: "hasExpander"
      },
      standalone: false,
      ngContentSelectors: _c1,
      decls: 13,
      vars: 9,
      consts: [[1, "status-message", "status-message-global", 3, "ngClass"], [1, "status-message-header"], [1, "status-message-image"], ["heading", "h1", "size", "lg", 3, "subtitle", "theme"], ["data-title", "", 1, "status-message-title-wrapper"], [1, "status-message-icon", 3, "ngClass"], [1, "status-message-title"], [1, "status-message-main"], ["triggerIconState", "right", "triggerSize", "sm", 1, "status-message-expander", 3, "isExpanded", "triggerText"], [1, "status-message-footer"], ["triggerIconState", "right", "triggerSize", "sm", 1, "status-message-expander", 3, "triggerClick", "isExpanded", "triggerText"]],
      template: function PrivaStatusGlobalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "section", 0)(1, "header", 1);
          i0.ɵɵtemplate(2, PrivaStatusGlobalComponent_Conditional_2_Template, 2, 0, "div", 2);
          i0.ɵɵelementStart(3, "priva-header", 3)(4, "span", 4);
          i0.ɵɵtemplate(5, PrivaStatusGlobalComponent_Conditional_5_Template, 1, 1, "span", 5);
          i0.ɵɵelementStart(6, "span", 6);
          i0.ɵɵtext(7);
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(8, "div", 7);
          i0.ɵɵprojection(9);
          i0.ɵɵtemplate(10, PrivaStatusGlobalComponent_Conditional_10_Template, 3, 4, "priva-expander", 8);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(11, "footer", 9);
          i0.ɵɵprojection(12, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c2, ctx.PrivaStatusGlobalStatus[ctx.status == null ? null : ctx.status.toLowerCase()]));
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.hasImage ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("subtitle", ctx.subtitle)("theme", ctx.theme);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.iconClass ? 5 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.title);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.hasExpander ? 10 : -1);
        }
      },
      dependencies: [i1.NgClass, i2.PrivaExpanderComponent, i3.PrivaHeaderComponent, i4.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block;margin:0 auto}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaStatusGlobalComponent, [{
    type: Component,
    args: [{
      selector: 'priva-status-global',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<section\n    class=\"status-message status-message-global\"\n    [ngClass]=\"[PrivaStatusGlobalStatus[status?.toLowerCase()]]\"\n>\n    <header class=\"status-message-header\">\n        @if (hasImage) {\n            <div class=\"status-message-image\">\n                <ng-content select=\"[data-image]\" />\n            </div>\n        }\n        <priva-header heading=\"h1\" size=\"lg\" [subtitle]=\"subtitle\" [theme]=\"theme\">\n            <span data-title class=\"status-message-title-wrapper\">\n                @if (iconClass) {\n                    <span class=\"status-message-icon\" [ngClass]=\"iconClass\"></span>\n                }\n                <span class=\"status-message-title\">{{ title }}</span>\n            </span>\n        </priva-header>\n    </header>\n    <div class=\"status-message-main\">\n        <ng-content />\n        @if (hasExpander) {\n            <priva-expander\n                class=\"status-message-expander\"\n                triggerIconState=\"right\"\n                [isExpanded]=\"isExpanded\"\n                triggerSize=\"sm\"\n                [triggerText]=\"\n                    (isExpanded ? 'GLOBAL.ACTION.EXPANDER.LABEL.OPEN' : 'GLOBAL.ACTION.EXPANDER.LABEL.CLOSE')\n                        | translate\n                \"\n                (triggerClick)=\"isExpanded = !isExpanded\"\n            >\n                <ng-content select=\"[data-expander]\" />\n            </priva-expander>\n        }\n    </div>\n    <footer class=\"status-message-footer\">\n        <ng-content select=\"[data-footer]\" />\n    </footer>\n</section>\n",
      styles: [":host{display:block;margin:0 auto}\n"]
    }]
  }], null, {
    status: [{
      type: Input
    }],
    iconClass: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    hasImage: [{
      type: Input
    }],
    hasExpander: [{
      type: Input
    }]
  });
})();
class PrivaStatusGlobalModule {
  static {
    this.ɵfac = function PrivaStatusGlobalModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaStatusGlobalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaStatusGlobalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaExpanderModule, PrivaHeaderModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaStatusGlobalModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaExpanderModule, PrivaHeaderModule, PrivaLocalizationModule],
      declarations: [PrivaStatusGlobalComponent],
      exports: [PrivaStatusGlobalComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaStatusGlobalComponent, PrivaStatusGlobalModule };
